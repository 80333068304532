import React from "react"
//import { FaBeer } from 'react-icons/fa';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageHeader, Banner } from '../utils'
import contactsImg from '../images/bg/contactsBg.jpg'


const ContactPage = () => (
  <Layout>
    <SEO title="Contact" keywords={[`project`, `team`, `people`]} />
    <PageHeader img={contactsImg}>
      <Banner title="Contact" subtitle="How to get in touch with us" />
    </PageHeader>

  </Layout>
)

export default ContactPage